import * as React from "react"
import '../../style.css'
import PPTCarousel from "../../components/carousel";
import Layout from "../../components/layout"

const FOM = () => {
    const info = {
        title:"Scale The Universe",
        folder:'/powerpoints/PIS',
        folderLength:13,
        slides:null,
        ppt:'scaletheuniverse.ppt',
        pptx:'scaletheuniverse.pptx'
    }

    let slides=[]
    for(let i = 1; i <= info.folderLength; i++){
        slides.push(`${info.folder}/Slide${i}`);
    }

    return (
        <Layout>
                <h1>{info.title}</h1>
                <PPTCarousel slides={slides}/>
                <p>Download the slides as a <a href={`${info.folder}/${info.ppt}`} download target='_blank' rel="noreferrer">ppt</a> or <a href={`${info.folder}/${info.pptx}`} download target='_blank' rel="noreferrer">pptx</a></p>
        </Layout>
  )
}

export default FOM